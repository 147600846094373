import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0;
    h1 {
        font-size: 10vw;
        color: #f24848;
    }
`;

export const Content = styled.div`
    width: 30vw;
    margin-top: 5rem;
    text-align: center;

    h2 {
        margin-bottom: 1rem;
    }
    @media (max-width: 425px) {
        width: 50vw;
        h5 {
            font-size: 0.5rem;
        }
    }
`;
