import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import MediaQuery from "react-responsive";
import { Carousel } from "react-responsive-carousel";
import * as Scroll from "react-scroll";

import {
    CarouselImageLandscape1,
    CarouselImageLandscape2,
    CarouselImageLandscape3,
    CarouselImageLandscape4,
    CarouselImagePortrait1,
    CarouselImagePortrait2,
    CarouselImagePortrait3,
    CarouselImagePortrait4,
} from "./importsBanner";
import { Image, Button } from "./styles";

export function ImageCarousel() {
    const { scroller } = Scroll;

    return (
        <>
            <MediaQuery orientation="landscape">
                <Carousel
                    autoPlay
                    infiniteLoop
                    showArrows={false}
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={false}
                    stopOnHover={false}
                    transitionTime={2000}
                    interval={4000}
                >
                    <Image>
                        <img
                            src={CarouselImageLandscape1}
                            alt="Imagem Banner FTB"
                        />
                    </Image>
                    <Image>
                        <img
                            src={CarouselImageLandscape2}
                            alt="Imagem Banner FTB"
                        />
                    </Image>
                    <Image>
                        <img
                            src={CarouselImageLandscape3}
                            alt="Imagem Banner FTB"
                        />
                    </Image>
                    <Image>
                        <img
                            src={CarouselImageLandscape4}
                            alt="Imagem Banner FTB"
                        />
                    </Image>
                </Carousel>
            </MediaQuery>
            <MediaQuery orientation="portrait">
                <Carousel
                    autoPlay
                    infiniteLoop
                    showArrows={false}
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={false}
                    stopOnHover={false}
                    transitionTime={2000}
                    interval={4000}
                >
                    <Image>
                        <img
                            src={CarouselImagePortrait1}
                            alt="Imagem Banner FTB"
                        />
                    </Image>
                    <Image>
                        <img
                            src={CarouselImagePortrait2}
                            alt="Imagem Banner FTB"
                        />
                    </Image>
                    <Image>
                        <img
                            src={CarouselImagePortrait3}
                            alt="Imagem Banner FTB"
                        />
                    </Image>
                    <Image>
                        <img
                            src={CarouselImagePortrait4}
                            alt="Imagem Banner FTB"
                        />
                    </Image>
                </Carousel>
            </MediaQuery>
            <Button
                onClick={() =>
                    scroller.scrollTo("myScroll", {
                        duration: 2000,
                        smooth: true,
                    })
                }
            >
                <BsFillArrowDownCircleFill size={50} color="#e67722EF" />
            </Button>
        </>
    );
}
