import AOS from "aos";
import React, { Component } from "react";
import { BiDownload } from "react-icons/bi";

import AboutImage1 from "../../Assets/AboutImage1.jpeg";
import AboutImage2 from "../../Assets/AboutImage2.jpeg";
import AboutImage3 from "../../Assets/AboutImage3.jpeg";
import AboutImage4 from "../../Assets/AboutImage4.jpeg";
import { Container, Title, Button } from "./styles";

export class BodyAbout extends Component {
    render() {
        AOS.init();
        return (
            <>
                <Title>
                    <h1>QUEM SOMOS</h1>
                </Title>
                <Container float="left">
                    <h1>ESTATUTO SOCIAL</h1>
                    <img
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                        src={AboutImage1}
                        alt="Imagem FTB"
                    />
                    <h5 data-aos="fade-up" data-aos-duration="2000">
                        Guerreiros e Guerreiras, sejam muito bem vindo(a)s a “
                        FORÇA TAREFA BRASILEIRA - F.T.B ”, milhões de pessoas
                        diariamente que são assoladas ou acometidas por algum
                        tipo de Evento Adverso, Catástrofe, Desastre ou Acidente
                        no Brasil e fora dele. A Força Tarefa Brasileira, atua
                        supletivamente, apoiando e laborando operacionalmente em
                        conjunto com as autoridades públicas ou privadas, na
                        prevenção ou na resposta aos eventos adversos, nas
                        urgências e emergências que acontece no Brasil ou no
                        mundo, desde 10 (dez) de agosto de 1997 (mil novecentos
                        e noventa e sete), até então, era o nome fantasia e o
                        braço operacional da “ Associação Brasileira de Resgate,
                        Busca e Salvamento - A.B.R.B.S. “, no entanto a Força
                        Tarefa Brasileira, como já era previsto pelos relevantes
                        serviços prestados ao Brasil e a outros países, em 01
                        (um) de Dezembro de 2019(dois mil e dezenove), após a
                        Assembleia Geral Ordinária da A.B.R.B.S., realizada no
                        plenário da Câmara Municipal de São Bernardo do
                        Campo/SP, foi decidido pelos presentes, que a Força
                        Tarefa Brasileira, após 23 anos de incansáveis missões e
                        êxitos, era chegada a hora de ter personalidade jurídica
                        própria, como novos rumos e objetivos ainda mais
                        audaciosos em prol a vidas alheias, assim de pronto, foi
                        realizado pelo fundadores, a Assembleia Geral Ordinária
                        de Fundação da Força Tarefa Brasileira - CNPJ:
                        40.336.027/0001-74, conforme a Lista de Presença, Ata de
                        Fundação e o Estatuto Social devidamente registrados no
                        Cartório de Registro de Títulos e Documentos e Registro
                        Civil das Pessoas Jurídicas da Comarca de Santos/ SP. A
                        Força Tarefa Brasileira, conta com uma equipe
                        multidisciplinar, oriunda da Associação Brasileira de
                        Resgate, Busca e Salvamento, composta por profissionais
                        com expertise e larga experiência e vivência operacional
                        no atendimento e resposta a desastres ou catástrofes,
                        todos são voluntários, e se preocupam com a vida dos
                        nossos semelhantes, fazemos o bem sem olhar ou se
                        importar a quem, apenas fazemos a nossa parte como
                        profissionais e pessoas que realmente ama pessoas. Caso
                        tenha interesse em ser um elo desta corrente inquebrável
                        do bem, junte-se a nós e venha fazer parte da associação
                        que reúne os melhores profissionais do Brasil. Nós
                        apenas estamos fazendo a nossa parte. E você fará a sua
                        ou não ? Maiores informações: contato@ftbr.or.br “ Na
                        incerteza do futuro, nos imprevistos da vida, a Força
                        Tarefa Brasileira tem a missão de garantir hoje, a
                        esperança do amanhã “
                    </h5>
                </Container>

                <Container float="right">
                    <h1>BREVE HISTÓRIA DA FORÇA TAREFA BRASILEIRA</h1>
                    <img
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                        src={AboutImage2}
                        alt="Imagem FTB"
                    />
                    <h5 data-aos="fade-up" data-aos-duration="2000">
                        {" "}
                        O Gênesis de tudo começou em 1996 quando o Sr. Galeno
                        Rosa, idealizador e fundador da Força Tarefa Brasileira,
                        foi convidado a fazer um curso de primeiros socorros na
                        AGRC - Associação do Grupamento de Resgate Civil, uma
                        ONG pioneira no segmento de voluntariado em emergências
                        e localizada no bairro do Jabaquara, Zona Sul de São
                        Paulo capital. Lá Galeno tornou-se voluntário e atendia
                        a emergências pré-hospitalares em vias urbanas, em apoio
                        às instituições públicas, além de outras atividades
                        operacionais. No dia 11 de junho de 1996, ao participar
                        “in loco”, de uma ocorrência de grande vulto, a saber:
                        um vazamento de gás GLP que provocou uma gigantesca
                        explosão no Shopping de Osasco na Grande São Paulo,
                        Galeno registra que era hora do almoço e véspera do Dia
                        dos Namorados, onde 42 pessoas morreram e mais de 100
                        ficaram feridas naquele trágico evento adverso. Já no
                        dia 31 de outubro de 1996, Galeno conta sobre sua
                        atuação no resgate das vítimas do vôo 402, um Fokker 100
                        da TAM, onde 99 pessoas morreram na tragédia. O vôo 402
                        decolou às 8h26 do dia 31 de outubro de 1996 do
                        Aeroporto de Congonhas, com destino ao Rio de Janeiro, e
                        caiu 24 segundos depois, sobre oito casas da Rua Luís
                        Orsini de Castro, no Jabaquara, cerca de 2 km do
                        aeroporto, na Zona Sul de São Paulo. organizar a
                        participação dos voluntários em cenários adversos. Após
                        participar operacionalmente destes marcantes eventos
                        adversos, Galeno observou certa desorganização, tanto
                        das agências públicas, quanto dos movimentos
                        voluntários, começando a pensar e a idealizar uma
                        associação que pudesse colaborar supletivamente com as
                        instituições e de fato
                    </h5>
                </Container>
                <Container float="left">
                    <h1>INSTITUCIONAL</h1>
                    <img
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                        src={AboutImage3}
                        alt="Imagem FTB"
                    />
                    <h5 data-aos="fade-up" data-aos-duration="2000">
                        {" "}
                        No início de 1997, Galeno conheceu no palácio da Polícia
                        Civil o médico e perito criminal Dr.Koryu Iha, que
                        estava participando da criação do SAMU do Vale do
                        Ribeira, de pronto o incentivou na criação e se colocou
                        à disposição para ser o médico responsável pela
                        Associação. Segundo Galeno a missão não foi fácil, mas
                        com a ajuda do seu pai Dr. Alberto Rosa, do seu amigo de
                        infância Clarence Igor Moraes, da Sra. Marina Macri, do
                        Sr. Afonso Luiz Roberto, do Sr. Adalberto dos Santos e
                        nos bastidores o Dr. koryu Iha, foi oficialmente criada
                        em 10 (dez) de agosto de 1997 a “Associação Brasileira
                        de Resgate, Busca e Salvamento - A.B.R.B.S.”, com o nome
                        fantasia “Força Tarefa Brasileira”. Em 01 de Dezembro de
                        2019, após realização da Assembleia Geral Ordinária da
                        A.B.R.B.S., realizada no plenário da Câmara Municipal de
                        São Bernardo do Campo/SP, foi decidido pelos presentes,
                        que a Força Tarefa Brasileira, após 23 anos de
                        incansáveis missões e êxitos, deveria ter personalidade
                        Passado algum tempo, percebeu-se que o nome Força Tarefa
                        Brasileira se destacava de forma assertiva e
                        contundente, como já era previsto, pois relevantes
                        serviços já haviam sido prestados ao Brasil e a outros
                        países pelos voluntários F.T.B. jurídica própria e
                        traçar novos rumos e objetivos mais audaciosos em prol
                        de vidas alheias salvar. Galeno de pronto, fez o edital
                        de convocação e foi realizado pelos fundadores ali
                        presentes, consignando em ata de Assembleia Geral
                        Ordinária de Fundação à personalidade jurídica própria
                        da Força Tarefa Brasileira cujo CNPJ: 40.336.027/0001-74
                        posteriormente foi registrado, em conformidade com a
                        lista de presença, ata de fundação e o Estatuto Social
                        que doravante foi devidamente registrado no Cartório de
                        Registro de Títulos e Documentos e Registro Civil das
                        Pessoas Jurídicas da Comarca de Santos, litoral de São
                        Paulo, para que se produzam os efeitos legais. A Força
                        Tarefa Brasileira, conta com uma equipe
                        multidisciplinar, oriunda da Associação Brasileira de
                        Resgate, Busca e Salvamento, composta por profissionais
                        com expertise e larga experiência e vivência operacional
                        no atendimento e resposta a desastres ou catástrofes.
                        Voluntários que se preocupam com a vida dos semelhantes,
                        fazendo o bem, sem olhar ou se importar a quem, e
                        produzir a preciosa parte, como profissionais e pessoas
                        que realmente amam pessoas.
                    </h5>
                </Container>
                <Container float="right">
                    <h1>
                        DOS OBJETIVOS DA FORÇA TAREFA BRASILEIRA E SEUS FINS
                    </h1>
                    <h5
                        data-aos="fade-up"
                        data-aos-duration="2000"
                        style={{ textAlign: "center" }}
                    >
                        O fito da Força Tarefa Brasileira é de caráter
                        assistencial, altruístico, humanitário, de socorro,
                        cívico-educativo, preventivo, mitigador, de preparação,
                        resposta, recuperação, norteando-se pelos seguintes
                        princípios:<p>I - Nacionalismo e cidadania plena;</p>
                        <p>II - Obediência e cumprimento às leis do país;</p>
                        <p>III - Hierarquia e disciplina; </p>
                        <p>
                            IV - Respeito aos Direitos e Garantias
                            Constitucionais;
                        </p>
                        <p>
                            {" "}
                            V - Preservação e defesa da vida, do meio ambiente,
                            defesa dos Direitos Humanos e as prerrogativas do
                            Direito Humanitário Internacional;
                        </p>
                        <p>
                            VI - Imparcialidade, neutralidade, independência e
                            transparência;
                        </p>
                        <p>
                            VII - Solidariedade, humildade, lealdade, trabalho,
                            tolerância e resiliência.
                        </p>
                    </h5>
                </Container>
                <Container float="left">
                    <h1>
                        A IMPORTÂNCIA, RELEVÂNCIA E PRINCÍPIOS DA FORÇA TAREFA
                        BRASILEIRA
                    </h1>
                    <img
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                        src={AboutImage4}
                        alt="Imagem FTB"
                    />
                    <h5 data-aos="fade-up" data-aos-duration="2000">
                        O direito à vida, à saúde, à segurança e à incolumidade
                        (manter livre de perigo) foi formalmente reconhecido
                        pela Constituição Federal e compete à Secretaria
                        Nacional de Proteção e Defesa Civil a garantia desse
                        direito, em circunstâncias de desastres, principalmente
                        através da implementação de ações preventivas e da
                        articulação institucional. Lei nº 12.608 de 10 de abril
                        de 2012 A lei 12.608/12 instituiu a Política Nacional de
                        Proteção e Defesa Civil - PNPDEC dispondo sobre o
                        Sistema Nacional de Proteção e Defesa Civil - SINPDEC e
                        o Conselho Nacional de Proteção e Defesa Civil –
                        CONPDEC; também autorizando a criação de sistema de
                        informações e monitoramento de desastres. No artigo 18
                        inciso IV lemos - Para fins do disposto nesta Lei,
                        consideram-se agentes de proteção e defesa civil: IV -
                        Os agentes voluntários, vinculados a entidades privadas
                        ou prestadores de serviços voluntários que exercem, em
                        caráter suplementar, serviços relacionados à proteção e
                        defesa civil. Portanto conforme previsto no texto legal,
                        bem como nos artigos 8º e 9º do Decreto 10.593 de
                        24/12/2020, os voluntários da Força Tarefa Brasileira
                        são considerados agentes de Defesa Civil, desta forma, a
                        F.T.B. é uma organização não governamental que integra o
                        SINPDEC Sistema Nacional de Proteção Civil. Neste
                        cenário a Força Tarefa Brasileira, contribui para o
                        fortalecimento da política pública de Proteção e Defesa
                        Civil, promovendo além do espírito colaborativo,
                        esforços e iniciativas voltadas a prevenção e ao
                        atendimento das pessoas afetadas por desastres em
                        diversas frentes de atuação
                    </h5>
                </Container>
                <Button>
                    <a
                        href="https://ftbr.org.br/static/media/ESTATUTO%20SOCIAL%20FTB.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <button type="button">
                            Estatuto Social
                            <p>
                                <BiDownload size={30} />
                            </p>
                        </button>
                    </a>
                </Button>
            </>
        );
    }
}
