import { BodyAbout } from "../components/BodyAbout";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

export function About() {
    return (
        <>
            <Header />
            <BodyAbout />
            <Footer />
        </>
    );
}
