import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    :root {
        --background: #ffffff;
        --red: #E52E4D;
        --blue: #5429CC;
        --blue-light: #6933ff;
        --text-title: #363F5F;
        --text-body: #969cb3;
        --shape: #ffffff;
        --button-old: #39C7BB;
    }

    *{          
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html{
        
        @media (max-width: 1080px) {
            font-size: 93.75%;
        }

        @media (max-width: 720px) {
            font-size: 87.5%;
        }

    }

    body {
        background: var(--background);
        -webkit-font-smoothing: antialised;
    }

    body, input, textarea, button {
        font-family: 'Poppins', sans-serif;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 600;
        cursor: default;
    }

    button {
        cursor: pointer;
    }

    [disables] {
        opacity: 0.6;
        cursor: not-allowed;
    }
`;
