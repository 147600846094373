import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    height: 9vh;
    background: linear-gradient(
        90deg,
        rgba(62, 198, 204, 1) 0%,
        rgba(18, 207, 46, 1) 46%,
        rgba(221, 246, 44, 1) 93%
    );
    align-items: center;
    justify-content: space-between;

    a {
        margin: 0 1vw;
    }
    @media (max-width: 670px) {
        justify-content: space-around;
        h4 {
            font-size: 0.7rem;
        }
    }
`;
