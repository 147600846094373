import React, { Component } from "react";

import { BodyHome } from "../components/BodyHome";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { ImageCarousel } from "../components/ImageCarousel";

export class Home extends Component {
    render() {
        return (
            <>
                <Header />
                <ImageCarousel />
                <BodyHome />
                <Footer />
            </>
        );
    }
}
