import AOS from "aos";
import React, { Component } from "react";
import * as Scroll from "react-scroll";

import { Container, Content } from "./styles";

import "aos/dist/aos.css";

export class BodyHome extends Component {
    render() {
        const { Element } = Scroll;
        AOS.init();
        return (
            <Container>
                <Element name="myScroll">
                    <h1 data-aos="zoom-in" data-aos-duration="3000">
                        BEM VINDOS!
                    </h1>
                </Element>
                <Content data-aos="fade-right" data-aos-duration="2000">
                    <h2 style={{ color: "rgba(62,198,204,1)" }}>MISSÃO</h2>
                    <h5>
                        Promover a sinergia necessária à integração, suporte e à
                        padronização de voluntários em missões nacionais e
                        internacionais, tendo em vista interagir na resposta,
                        bem como em ações de conscientização e prevenção,
                        respeitando, contudo, as limitações e a urgência desta
                        missão em meio ao cenário de incertezas dos eventos
                        adversos.
                    </h5>
                </Content>
                <Content data-aos="fade-left" data-aos-duration="2000">
                    <div>
                        <h2 style={{ color: "rgba(18,207,46,1)" }}>VISÃO</h2>
                    </div>
                    <h5>
                        Ser referência nacional na organização de voluntários e
                        símbolo de excelência e profissionalismo na pronta
                        resposta e nas frentes de prevenção em emergência no
                        menor tempo possível, através de coordenadorias em todo
                        território nacional, agindo junto com os órgãos públicos
                        dentro de um único padrão de atendimento.
                    </h5>
                </Content>
                <Content data-aos="fade-right" data-aos-duration="2000">
                    <h2 style={{ color: "#ffbb00" }}>VALOR</h2>
                    <h5>
                        Agir em prol da vida, em ações de proteção e preservação
                        do meio ambiente e do patrimônio, abstendo-se de forma
                        altruísta e voluntária de status ou conforto, visando
                        atuar supletivamente em conjunto com as agências
                        públicas e privadas nos mais diversos níveis de
                        potencial de dano.
                    </h5>
                </Content>
            </Container>
        );
    }
}
