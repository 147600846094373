import styled from "styled-components";

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    height: 71vh;
`;

export const Card = styled.div`
    @media (min-width: 706px) {
        display: flex;
        flex-direction: column;
        margin: 2.5vw;
        width: 30vw;
        height: 30vh;
        background-color: #09dea9;
        align-items: center;

        hr {
            border: 0.5px solid #2e2e2e50;
            width: 25vw;
        }

        div {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        h4 {
            color: #333333;
            text-align: center;
        }
    }
    @media (max-width: 705px) {
        display: flex;
        background-color: #09dea9;
        align-items: center;
        margin: 3vh;
        width: 80vw;
        height: 14vh;
        hr {
            border: 0.5px solid #2e2e2e50;
            height: 10vh;
        }
        div {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        h4 {
            color: #333333;
            text-align: center;
        }
    }
`;

export const ContainerCard = styled.div`
    @media (max-width: 705px) {
        display: block;
        margin: 0 auto;
        .icon {
            margin: 0vh 3vw;
            color: #454545;
        }
    }
    @media (min-width: 706px) {
        display: flex;
        margin-top: 5vh;
        width: 100vw;

        .icon {
            margin: 3vh 0vw;
            color: #454545;
        }
    }
`;

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5vh;
    @media (max-width: 705px) {
        margin-top: 3vh;
    }

    h1 {
        align-items: center;

        @media (max-width: 408px) {
            font-size: 3rem;
        }
        font-size: 4rem;
        color: #32a852;
    }
`;
