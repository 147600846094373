import { HashRouter as Router, Switch, Route } from "react-router-dom";

import { About } from "./pages/about";
import { Contact } from "./pages/contact";
import { Home } from "./pages/home";
import { GlobalStyle } from "./styles/global";

export function App() {
    return (
        <>
            <GlobalStyle />
            <Router>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/contact" component={Contact} />
                </Switch>
            </Router>
        </>
    );
}
