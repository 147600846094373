import styled from "styled-components";

interface ContainerProps {
    float: string;
}

export const Container = styled.div<ContainerProps>`
    margin-bottom: 10vh;
    h1 {
        display: flex;
        justify-content: center;
        text-align: center;
        margin: 0 8vw;
    }
    h5 {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        margin: 3vh 10vh;
        @media (min-width: 1920px) {
            font-size: 1.2rem;
        }
        @media (max-width: 705px) {
            text-align: center;
        }
    }

    img {
        @media (min-width: 706px) {
            float: ${(props) => props.float};
            margin: ${(props) =>
                props.float === "left"
                    ? "3vh 2vh 1vh 10vh"
                    : "3vh 10vh 1vh 2vh"};
            width: 40vw;
        }
        @media (max-width: 705px) {
            display: block;
            width: 60vw;
            margin: 0 auto;
        }
    }
`;

export const Title = styled.h1`
    text-align: center;
    color: #90ba06;
    margin: 4vh 0 10vh;
`;

export const Button = styled.div`
    margin: 12vw 0vh 10vh;
    @media (max-width: 705px) {
        margin: 10vh 0vh 10vh;
    }
    display: flex;
    justify-content: center;
    a {
        &:hover {
            filter: brightness(1.3);
        }
    }
    button {
        font-size: 1rem;
        padding: 1vh;
        border: none;
        color: #fff;
        text-align: center;
        text-decoration: none;
        background-color: #f44336;
        border-radius: 16px;
    }
`;
