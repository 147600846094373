import styled from "styled-components";

export const Image = styled.div`
    img {
        height: 81vh;
    }
`;

export const Button = styled.button`
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;

    @keyframes bounce {
        0%,
        20%,
        50%,
        80%,
        100% {
            transform: translateY(0);
        }
        40% {
            transform: translateY(-30px);
        }
        60% {
            transform: translateY(-15px);
        }
    }
    position: absolute;
    top: 90%;
    left: 50%;
    border: none;
    background: none;
`;
