import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";

import logoImg from "../../Assets/logo.svg";
import { Container, Content, Title, Buttons } from "./styles";

export function Header() {
    return (
        <Container>
            <Content>
                <img src={logoImg} alt="FTB" />
                <Title>
                    <MediaQuery minWidth={706} minHeight={530}>
                        <h1>FORÇA TAREFA BRASILEIRA</h1>
                        <h6>
                            A Força Tarefa Brasileira atua supletivamente,
                            apoiando e laborando operacionalmente em conjunto
                            com as autoridades públicas ou privadas, na
                            prevenção ou na resposta aos eventos adversos, nas
                            urgências e emergências que acontece no Brasil ou no
                            mundo!
                        </h6>
                    </MediaQuery>
                    <MediaQuery
                        maxWidth={705}
                        max-height={529}
                        orientation="portrait"
                    >
                        <h1>FORÇA</h1>
                        <h1>TAREFA</h1>
                        <h1>BRASILEIRA</h1>
                    </MediaQuery>
                    <MediaQuery
                        maxHeight={529}
                        maxWidth={705}
                        orientation="landscape"
                    >
                        <h1>FORÇA TAREFA BRASILEIRA</h1>
                    </MediaQuery>
                </Title>
                <Buttons>
                    <Link style={{ textDecoration: "none" }} to="/">
                        Início
                    </Link>
                    <Link style={{ textDecoration: "none" }} to="/about">
                        Sobre
                    </Link>
                    <Link style={{ textDecoration: "none" }} to="/contact">
                        Contato
                    </Link>
                </Buttons>
            </Content>
        </Container>
    );
}
