import AOS from "aos";
import React, { Component } from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { RiMapPinLine } from "react-icons/ri";

import { ContainerCard, Container, Box, Card } from "./styles";

export class BodyContact extends Component {
    render() {
        AOS.init();
        return (
            <Box>
                <Container>
                    <h1>Contate-nos!</h1>
                </Container>
                <ContainerCard>
                    <Card
                        data-aos="flip-left"
                        data-aos-duration="2000"
                        className="card"
                        data-aos-offset="0"
                    >
                        <MdEmail className="icon" size="11vh" />
                        <hr />
                        <div>
                            <h4>contato@ftbr.org.br</h4>
                        </div>
                    </Card>
                    <Card
                        data-aos="flip-left"
                        data-aos-duration="2000"
                        className="card"
                        data-aos-offset="0"
                    >
                        <BsFillTelephoneFill className="icon" size="11vh" />
                        <hr />
                        <div>
                            <h4>(11) 96626 - 2440</h4>
                        </div>
                    </Card>
                    <Card
                        data-aos="flip-left"
                        data-aos-duration="2000"
                        className="card"
                        data-aos-offset="0"
                    >
                        <RiMapPinLine className="icon" size="11vh" />
                        <hr />
                        <div>
                            <h4>Avenida Principal de Monte Cabrão</h4>
                            <h4>N° 460, Santos / SP</h4>
                            <h4>CEP: 11220-991</h4>
                        </div>
                    </Card>
                </ContainerCard>
            </Box>
        );
    }
}
