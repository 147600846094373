import { BodyContact } from "../components/BodyContact";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

export function Contact() {
    return (
        <>
            <Header />
            <BodyContact />
            <Footer />
        </>
    );
}
