import { BsWhatsapp, BsInstagram, BsFacebook, BsYoutube } from "react-icons/bs";
import MediaQuery from "react-responsive";

import { Container } from "./styles";

export function Footer() {
    return (
        <Container>
            <MediaQuery minWidth={671}>
                <div style={{ visibility: "hidden" }}>
                    <a
                        href="https://api.whatsapp.com/send?phone=5511966262440"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <BsWhatsapp size="3vh" color="#066100" />
                    </a>
                    <a
                        href="https://www.instagram.com/ftb01oficial/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <BsInstagram size="3vh" color="#ff6565" />
                    </a>
                    <a
                        href="https://www.facebook.com/share/15mTYwe7ZU/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <BsFacebook size="3vh" color="#0083db" />
                    </a>
                    <a
                        href="https://www.youtube.com/@forcatarefabrasileira992/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <BsYoutube size="3vh" color="#db0000" />
                    </a>
                </div>
            </MediaQuery>
            <h4>© 2025 FORÇA TAREFA BRASILEIRA - F.T.B</h4>
            <div>
                <a
                    href="https://api.whatsapp.com/send?phone=5511966262440"
                    target="_blank"
                    rel="noreferrer"
                >
                    <BsWhatsapp size="3vh" color="#066100" />
                </a>
                <a
                    href="https://www.instagram.com/ftb01oficial/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <BsInstagram size="3vh" color="#ff6565" />
                </a>
                <a
                    href="https://www.facebook.com/share/15mTYwe7ZU/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <BsFacebook size="3vh" color="#0083db" />
                </a>
                <a
                    href="https://www.youtube.com/@forcatarefabrasileira992/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <BsYoutube size="3vh" color="#db0000" />
                </a>
            </div>
        </Container>
    );
}
