import styled from "styled-components";

export const Container = styled.header`
    background: linear-gradient(
        90deg,
        rgba(62, 198, 204, 1) 0%,
        rgba(18, 207, 46, 1) 46%,
        rgba(221, 246, 44, 1) 93%
    );
`;

export const Content = styled.div`
    margin: 0 auto;
    padding: 0.5vh 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 20vh;
    img {
        height: 18vh;
        @media (max-width: 705px) {
            height: 15vh;
        }
    }
`;

export const Title = styled.div`
    h6 {
        margin-top: 1vh;
        width: 27rem;
        color: #363636;
        text-align: center;
    }
    h1 {
        text-align: center;
        font-size: 2.5rem;
        font-weight: 500;
        color: #3d3d3d;
        font-family: "Black Ops One", cursive;
        @media (max-width: 705px) {
            font-size: 1.8rem;
        }
        @media (max-height: 529px) and (orientation: landscape) {
            font-size: 1.5rem;
        }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a {
        text-align: center;
        transition: filter 1s;
        padding: 0 3vw;
        margin: 0.7vh 0;
        background-color: var(--button-old);
        border: 2px solid #fcfc0c;
        border-radius: 15px;
        color: #fff;

        &:hover {
            filter: brightness(1.2);
        }
    }
    @media (max-height: 529px) and (orientation: landscape) {
        a {
            font-size: 2vh;
        }
    }
`;
